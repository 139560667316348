import React from 'react';

import type { DealData } from '../../../types/DealData';
import getPriceType from '../../../utils/getPriceType';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import PriceDeal from './PriceDeal';
import PriceExtraDealsFooter from './PriceExtraDealsFooter';
import styles from './styles/price-retail.css';

const PriceRetail: React.FC = () => (
  <Consumer>
    {(value): JSX.Element => {
      // TODO: we should remove default value of {} for dealData
      const {
        showReviewsPosition,
        widgetTitle,
        site,
        showPrice,
        name,
        dealData = {} as DealData,
        genericSharedComponents: { DisplayPrice, ModelCodeReviews, Title },
      } = value;
      const deal = dealData && dealData.deals && dealData.deals[0] ? dealData.deals[0] : null;
      const className = name === 'Need to know' ? 'need-to-know' : 'container';

      return (
        <>
          <Title
            title={widgetTitle}
            className={
              site && site.type === 'responsive' ? 'price-title-responsive' : 'price-widget'
            }
          />
          <div className={styles[className]}>
            {showReviewsPosition === 'top' && <ModelCodeReviews />}
            <div className={styles.main}>
              {showPrice === 'availableFrom' &&
                deal &&
                deal.offer.price &&
                deal.product_type !== 200 && (
                  <div className={styles['available-from']}>
                    Available From
                    <DisplayPrice
                      {...{
                        deal,
                        type: getPriceType(deal),
                        className: 'available-from',
                        forceAppear: true,
                      }}
                    />
                  </div>
                )}
              <PriceDeal
                {...{
                  deal,
                }}
              />
              <PriceExtraDealsFooter />
            </div>
            {['default', 'bottom'].indexOf(showReviewsPosition) >= 0 && <ModelCodeReviews />}
          </div>
        </>
      );
    }}
  </Consumer>
);

export default PriceRetail;
