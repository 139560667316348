import React from 'react';

import styles from './styles/search-icon.css';

export const SearchIcon: React.FC = () => {
  return (
    <svg
      {...{
        version: '1.1',
        id: 'Layer_1',
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
        viewBox: '0 0 15 15',
        enableBackground: 'new 0 0 15 15',
        xmlSpace: 'preserve',
        className: styles.svg,
        width: '15px',
        height: '15px',
      }}
    >
      <path
        className={styles.path}
        d="M10.802,1.853c-2.471-2.47-6.478-2.47-8.949,0c-2.47,2.472-2.47,6.477,0,8.946
	c2.131,2.134,5.403,2.421,7.848,0.875L13.026,15L15,13.027l-3.324-3.325C13.223,7.256,12.933,3.985,10.802,1.853z M9.414,9.412
	c-1.705,1.704-4.469,1.704-6.171,0c-1.706-1.704-1.706-4.467,0-6.17c1.703-1.706,4.466-1.706,6.171,0
	C11.117,4.945,11.117,7.708,9.414,9.412z"
      />
    </svg>
  );
};
