import React from 'react';

import Footer from '../../GenericComponents/Footer/Footer';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import PriceExtraDeal from './PriceExtraDeal';

const PriceExtraDealsFooter: React.FC = () => (
  <Consumer>
    {(value): JSX.Element | null => {
      const { dealData, translate, allDealsLinkText, showLink } = value;
      const extraDeals =
        dealData.deals && dealData.deals.length > 1 ? dealData.deals.slice(1) : null;

      if (extraDeals && extraDeals.length && extraDeals.length > 0) {
        return (
          <>
            {extraDeals.map((deal) => (
              <PriceExtraDeal
                {...{
                  deal,
                  key: `${deal?.id}-${(deal?.merchant || {})?.name}`,
                }}
              />
            ))}
            {showLink && (allDealsLinkText || dealData.totalDeals) ? (
              <Footer
                {...{
                  className: 'price-widget',
                  label:
                    allDealsLinkText ||
                    translate('priceFooterLabel', [String(dealData.totalDeals)]),
                }}
              />
            ) : null}
          </>
        );
      }
      return null;
    }}
  </Consumer>
);

export default PriceExtraDealsFooter;
