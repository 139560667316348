import React from 'react';

import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import PriceSubscriptionsDeal from './PriceSubscriptionsDeal';
import PriceSubscriptionsLink from './PriceSubscriptionsLink';
import styles from './styles/price-subscriptions.css';

interface PriceSubscriptionsProps {
  className: string;
}

const PriceSubscriptions: React.FC<PriceSubscriptionsProps> = ({ className }) => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        widgetTitle,
        genericSharedComponents: { Title },
      } = value;
      let { dealData } = value;
      dealData = dealData || {};
      const containerStyle = styles[className] ? className : 'container';

      return (
        <>
          <Title title={widgetTitle} className="price-widget" />
          <div className={styles[containerStyle]}>
            {(dealData.deals || []).map((deal) => (
              <PriceSubscriptionsDeal
                {...{
                  deal,
                  key: deal?.id,
                }}
              />
            ))}
            <PriceSubscriptionsLink
              {...{
                deal: dealData.deals && dealData.deals[0],
              }}
            />
          </div>
        </>
      );
    }}
  </Consumer>
);

export default PriceSubscriptions;
