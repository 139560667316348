import React from 'react';

import { getClickType } from '../../../../sharedModules/getClickType';
import type { Deal } from '../../../types/Deal';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import styles from './styles/price-subscriptions-link.css';

interface PriceSubscriptionsLinkProps {
  className?: string;
  deal: Deal;
}

const PriceSubscriptionsLink: React.FC<PriceSubscriptionsLinkProps> = ({
  className,
  deal = {} as Deal,
}) => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        articleUrl,
        translate,
        genericSharedComponents: { AffiliateLink },
      } = value;
      const merchantName = deal?.merchant?.name ?? '';
      const linkText = deal?.offer?.all_deals_link_text ?? translate('priceLinkLinkText');
      const containerStyle = className && styles[className] ? className : 'container';

      return (
        <div className={styles[containerStyle]}>
          <AffiliateLink
            deal={deal}
            className="price-widget-button"
            clickType={getClickType({
              link: deal.offer.link,
              articleUrl,
              productType: deal.product_type,
            })}
          >
            {linkText}
          </AffiliateLink>
          <AffiliateLink
            deal={deal}
            className="price-subs"
            clickType={getClickType({
              link: deal.offer.link,
              articleUrl,
              productType: deal.product_type,
            })}
          >
            {translate('priceLinkAtMerchant', [merchantName])}
          </AffiliateLink>
        </div>
      );
    }}
  </Consumer>
);

export default PriceSubscriptionsLink;
